<template>
  <div class="report-mobile">
    <ReportContent v-if="showReport" />
  </div>
</template>
<script>
import ReportContent from '@/components/ReportContent'
import { loginDirect } from '@/api/admin'
export default {
  components: {
    ReportContent,
  },
  data() {
    return {
      showReport: false,
      ud: '',
      overtime: '',
      vcode: '',
    }
  },
  created() {
    this.ud = this.$route.query.ud || ''
    this.overtime = this.$route.query.overtime || ''
    this.vcode = this.$route.query.vcode || ''

    if (this.ud && this.overtime && this.vcode) {
      loginDirect({
        ud: this.ud, //用户id
        overtime: this.overtime, //面试结束时间
        vcode: this.vcode, //加密字符串
      }).then(res => {
        if (res && res.code === 0) {
          this.$store.commit('setUserInfo', res.data)
          this.$store.commit('setShowAiVideo', res.data.isShowAiVideo)
          this.showReport = true
        } else if (res.msg) {
          this.$toast({ type: 'fail', message: res.msg })
        }
      })
    } else {
      this.$toast({ type: 'fail', message: '缺少参数' })
    }
  },
}
</script>
